import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import oshkosh from "../assets/oshkosh.png";
import nerf from "../assets/nerf.jpg";
import pigs from "../assets/yoyo.jpeg";
import imlab from "../assets/immersionlabbg.jpg";
import e33 from "../assets/e33.jpg";
import lathe from "../assets/lathecad.png";
//TODO create projects page for all projects mariia wants to add
const Projects = () => {
  return (
    <div style={{ backgroundColor: "#fdfcfb", padding: 20 }}>
      <p style={{ paddingTop: 50, textAlign: "center", fontSize: "xxx-large" }}>
        <b>Projects</b>
      </p>
      {/* 1st Row */}
      <Row>
      <Col style={{ padding: 50, backgroundColor: "#fdfcfb", margin: 30 }}>
          <center><Image src={lathe} width="75%" /></center>
          <div
            style={{ textAlign: "center", fontSize: "x-large", marginTop: 50 }}
          >
            <b>
              <em>
                <p>Tabletop Lathe </p>
              </em>
              <p>Design & Fab</p>
            </b>
          </div>
          <br />
          <p style={{ fontSize: "large" }}>
          Led a cross-functional team of 6 in the rapid design
           and fabrication of a tabletop lathe, successfully navigating strict time and budget
            constraints make a lathe with sub 50 micron precision in 3 months.
          </p>
          <br />
          <em>
            <p style={{ fontSize: "large" }}>
              Tools: Solidworks (CAD, FEA, Collaboration),Tolerance Stacking, DFM, Waterjet, Stress, Communication .
            </p>
          </em>
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                borderRadius: 0,
                backgroundColor: "transparent",
                paddingTop: 20,
                paddingBottom: 20,
                paddingRight: 40,
                paddingLeft: 40,
                color: "#209d50",
                borderColor: "#209d50",
                borderWidth: 3,
              }}
              href="/lathe"
            >
              <b>Learn More </b>
            </Button>
          </div>
        </Col>
        <Col style={{ padding: 50, backgroundColor: "#fdfcfb", margin: 30 }}>
          <Image src={oshkosh} width="100%" />
          <br />
          <br />
          <div
            style={{ textAlign: "center", fontSize: "x-large", marginTop: 50 }}
          >
            <b>
              <em>
                <p>MIT - Oshkosh</p>
              </em>
              <p>Next Gen hydraulics without fluids</p>
            </b>
          </div>
          <br />
          <p style={{ fontSize: "large" }}>
            Designed with Oshkosh next-generation high-force long-stroke
            electromechanical linear actuators that can fully replace their
            hydraulics in effort to electrify their entire fleet.
          </p>
          <br />
          <em>
            <p style={{ fontSize: "large" }}>
              Tools: CAD, CAM, CNC, DFM, Stress, Communication, Customer Value
              Chain, Market Research.
            </p>
          </em>
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                borderRadius: 0,
                backgroundColor: "transparent",
                paddingTop: 20,
                paddingBottom: 20,
                paddingRight: 40,
                paddingLeft: 40,
                color: "#209d50",
                borderColor: "#209d50",
                borderWidth: 3,
              }}
              href="/oshkosh"
            >
              <b>Learn More </b>
            </Button>
          </div>
        </Col>
        <Col style={{ padding: 50, backgroundColor: "#fdfcfb", margin: 30 }}>
          <Image src={pigs} width="100%" />
          <br />
          <br />
          <div style={{ textAlign: "center", fontSize: "x-large" }}>
            <b>
              <em>
                <p>MIT - 2.008</p>
              </em>
              <p>Custom Mass-Manufactured Yo-yos</p>
            </b>
          </div>
          <br />
          <p style={{ fontSize: "large" }}>
            Produced, desgined, inspected, and assembled 130 yo-yos in 4.5 min
            per yo-yo. All measured parts had a successful fit with the
            interference of 0.0095’’ for Manufacturing class at MIT.
          </p>
          <br />
          <em>
            <p style={{ fontSize: "large" }}>
              Tools: CAD, CAM, CNC, Thermoform, Injection Molding, Tolerances,
              Press Fit, Manufacturing.
            </p>
          </em>
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                borderRadius: 0,
                backgroundColor: "transparent",
                paddingTop: 20,
                paddingBottom: 20,
                paddingRight: 40,
                paddingLeft: 40,
                color: "#209d50",
                borderColor: "#209d50",
                borderWidth: 3,
              }}
              href="/yoyo"
            >
              <b>Learn More</b>
            </Button>
          </div>
        </Col>
        
      </Row>
      {/* 2nd row */}
      <Row>
      <Col style={{ padding: 50, backgroundColor: "#fdfcfb", margin: 30 }}>
          <Image src={nerf} width="100%" style={{ marginTop: -30 }} />
          <br />
          <br />
          <div style={{ textAlign: "center", fontSize: "x-large" }}>
            <b>
              <em>
                <p>TDC Hackathon</p>
              </em>
              <p>Automated Nerf-Gun Turret</p>
            </b>
          </div>
          <br />
          <p style={{ fontSize: "large" }}>
            Designed a 2 Degree-of-Freedom motor-operated mount for a nerf gun
            creating a turret able to follow and hit a human walking around the
            room. With this project, my teammate and I won a PS5 for 1st place
            at a TDC hackathon.
          </p>
          <br />
          <em>
            <p style={{ fontSize: "large" }}>
              Tools: CAD, 3D printing, electronics and motors.
            </p>
          </em>
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                borderRadius: 0,
                backgroundColor: "transparent",
                paddingTop: 20,
                paddingBottom: 20,
                paddingRight: 40,
                paddingLeft: 40,
                color: "#209d50",
                borderColor: "#209d50",
                borderWidth: 3,
                marginTop: 30,
              }}
              href="/nerf"
            >
              <b>Learn More</b>
            </Button>
          </div>
        </Col>
        <Col style={{ padding: 50, backgroundColor: "#fdfcfb", margin: 30 }}>
          <Image src={imlab} width="100%" />
          <br />
          <br />
          <div
            style={{ textAlign: "center", fontSize: "x-large", marginTop: 50 }}
          >
            <b>
              <em>
                <p>MIT.Nano - Research</p>
              </em>
              <p>Biomechanics and Human Gait</p>
            </b>
          </div>
          <br />
          <p style={{ fontSize: "large" }}>
          Developed and co-led $1M biomechanics data collection projects. Automated data visualization (using Python, bash, blender) for 100+ gb
          of data, reducing manual process time by 90%
          </p>
          <br />
          <em>
            <p style={{ fontSize: "large" }}>
              Tools: Experiment Design, Human Subject Research, Data analysis.
            </p>
          </em>
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                borderRadius: 0,
                backgroundColor: "transparent",
                paddingTop: 20,
                paddingBottom: 20,
                paddingRight: 40,
                paddingLeft: 40,
                color: "#209d50",
                borderColor: "#209d50",
                borderWidth: 3,
              }}
              href="/Research"
            >
              <b>Learn More </b>
            </Button>
          </div>
        </Col>
        <Col style={{ padding: 50, backgroundColor: "#fdfcfb", margin: 30 }}>
          <Image src={e33} width="100%" />
          <br />
          <br />
          <div style={{ textAlign: "center", fontSize: "x-large" }}>
            <b>
              <em>
                <p>E33 Lighting Design</p>
              </em>
              <p>Project Management and Lighting Design</p>
            </b>
          </div>
          <br />
          <p style={{ fontSize: "large" }}>
          Designed and set up live lighting for 15+ clients for audiences of up to 1000 people. Managed weekly charges of the group with >50 shows per semester and over $30k revenue. Organized and led New Member Training for 40+ new members.
          </p>
          <br />
          <em>
            <p style={{ fontSize: "large" }}>
              Tools: Lighting Design, Treasury, Client Management, Communication, Attention to detail. 
            </p>
          </em>
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                borderRadius: 0,
                backgroundColor: "transparent",
                paddingTop: 20,
                paddingBottom: 20,
                paddingRight: 40,
                paddingLeft: 40,
                color: "#209d50",
                borderColor: "#209d50",
                borderWidth: 3,
              }}
              href="http://e33.mit.edu/"
            >
              <b>Learn More</b>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Projects;
