import React from "react";
import Intro from "../components/Intro";
import Skills from "../components/Skills";
import Projects from "../components/Projects";

const Home = () => {
  return (
    <div>
      <Intro />
      <Skills />
      <Projects />
    </div>
  );
};

export default Home;
