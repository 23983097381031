import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Skills = () => {
  return (
    <div style={{ backgroundColor: "#fdfcfb" }}>
      <br />

      <Container style={{ marginTop: 50 }}>
        <p
          style={{ paddingTop: 50, textAlign: "center", fontSize: "xxx-large" }}
        >
          <b>Skills</b>
        </p>
        <br />
        <Row>
          <Col style={{ backgroundColor: "#e1dfd9", margin: 30 }}>
            <p
              style={{
                paddingTop: 20,
                textAlign: "center",
                fontSize: "xx-large",
              }}
            >
              <em>
                <b>Manufacture</b>
              </em>
            </p>
            <br />
            <ul style={{ fontSize: "x-large" }}>
              <li>Operations Improvement, Cycle Reduction,  GMP</li>
              <li>Milling, CNC, Injection Molding, Thermoforming</li>
              <li>3D printing, Laser Cutting</li>
            </ul>
            <br />
          </Col>
          <Col style={{ backgroundColor: "#e1dfd9", margin: 30 }}>
            <p
              style={{
                paddingTop: 20,
                textAlign: "center",
                fontSize: "xx-large",
              }}
            >
              <em>
                <b>Software</b>
              </em>
            </p>
            <br />

            <ul style={{ fontSize: "x-large" }}>
              <li>Fusion 360. Blender</li>
              <li>Matlab, Bash, Arduino</li>
              <li>Python: pandas, numpy, ffmpeg</li>
              <li>Adobe Illustrator</li>
            </ul>
            <br />
          </Col>
          <Col style={{ backgroundColor: "#e1dfd9", margin: 30 }}>
            <p
              style={{
                paddingTop: 20,
                textAlign: "center",
                fontSize: "xx-large",
              }}
            >
              <em>
                <b>Movement Analysis</b>
              </em>
            </p>
            <br />

            <ul style={{ fontSize: "x-large" }}>
              <li>Tensegrity structure analysis</li>
              <li>Motion Capture - Optitrack Motive</li>
              <li>EMG - Delsys Trigno</li>
              <li>3D Avatars - Reality Capture</li>
            </ul>
            <br />
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Skills;
