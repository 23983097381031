import React from "react";
import { AiFillLinkedin, AiOutlineMail } from "react-icons/ai";

import { Navbar, Nav, Container, Button } from "react-bootstrap";
const NavBar = () => {
  return (
    <Navbar
      bg="transparent"
      expand="md"
      style={{ paddingRight: 50, paddingLeft: 50 }}
    >
      <Navbar.Brand href="/" style={{ fontSize: "x-large" }}>
        Mariia Smyk
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav">
        <span className="navbar-toggler-icon"></span>
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          className="ms-auto"
          style={{
            alignItems: "center",
          }}
        >
          <Nav.Link
            href="/about"
            style={{
              justifyContent: "center",
              fontSize: "x-large",
              color: "black",
            }}
          >
            About
          </Nav.Link>
          <a
            href="https://www.linkedin.com/in/mariiasmyk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillLinkedin style={{ fontSize: "xxx-large", margin: 10 }} />
          </a>
          <a
            href="mailto:msmyk@mit.edu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiOutlineMail
              style={{ fontSize: "xxx-large", color: "black", margin: 10 }}
            />
          </a>
          <Button
            style={{
              borderRadius: 0,
              backgroundColor: "transparent",
              paddingTop: 20,
              paddingBottom: 20,
              paddingRight: 40,
              paddingLeft: 40,
              margin: 10,
              color: "#209d50",
              borderColor: "#209d50",
              borderWidth: 3,
            }}
            href="https://drive.google.com/file/d/1EIjHnFMyIMGNZVbK9-fb4aTO7LPwacQS/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>Resume</b>
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
