import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillLinkedin, AiOutlineMail } from "react-icons/ai";

const Footer = () => {
  return (
    <div style={{ backgroundColor: "rgb(17, 16, 16)", padding: 50 }}>
      <Row>
        <Col sm={8}>
          <a href="/" style={{ color: "#209d50" }}>
            <p style={{ color: "#209d50", fontSize: "xxx-large" }}>
              <b>Mariia Smyk</b>
            </p>
          </a>

          <p style={{ color: "#e1dfd9", fontSize: "x-large" }}>
            I enjoy analyzing complex systems, from manufacturing line to human movement, and making them more intuitive. I also enjoy
             theater design.{" "}
          </p>
        </Col>
        <Col sm={4} style={{ textAlign: "center" }}>
          {/* <Row style={{ color: "#209d50" }}>
            <Col>
              <a href="#" style={{ color: "#209d50" }}>
                Projects
              </a>
             
            </Col>
          </Row> */}
          <Row>
            <Col>
              <a
                href="https://www.linkedin.com/in/mariiasmyk/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#fdfcfb", fontSize: "xxx-large" }}
              >
                <AiFillLinkedin />
              </a>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <a
                href="mailto:msmyk@mit.edu"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#fdfcfb", fontSize: "xxx-large" }}
              >
                <AiOutlineMail />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
