import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import photo from "../assets/msmyk-photo.jpeg";
import { ImArrowDown } from "react-icons/im";
const Intro = () => {
  //TODO add padding to image for mobile version
  return (
    <Container style={{ marginTop: 20, marginBottom:50 }}>
      <Row style={{ alignItems: "center" }}>
        <Col style={{}}>
          <Image
            src={photo}
            style={{
              alignSelf: "center",
              width: "100%",
              padding: 20,
            }}
          />
        </Col>
        <Col
          style={{
            textAlign: "left",
            fontSize: "xxx-large",
            padding: 20,
          }}
        >
          <hr />
          <p>Hello,</p>
          <br />
          <p>
            I’m a <em>Mechanical Engineer</em> from <b>MIT</b>.
          </p>
          <p>
            Passionate about <b>manufacturing</b> and <b>bio-inspired design</b>
            .
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: 50 }}>
        <ImArrowDown />
      </Row>
    </Container>
  );
};

export default Intro;
