import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import yoyo from "../assets/yoyo.jpeg";
import yoyo2 from "../assets/yoyo2.png";
import yoyo3 from "../assets/yoyo3.png";
import { ImArrowDown } from "react-icons/im";

const YoYo = () => {
  return (
    <div style={{ marginTop: 50, backgroundColor: "transparent" }}>
      <Container>
        <h2
          style={{ textAlign: "center", fontSize: "xxx-large", marginTop: 50 }}
        >
          <b>CUSTOM MASS-MANUFACTURED YO-YOs</b>
        </h2>
        <br />
        <br />
        <Container style={{ textAlign: "center" }}>
          <p>
            As a team of 5, I designed, produced, inspected, and assembled 130
            yo-yos, reducing assembly time to 4.5 min per yo-yo. All measured
            parts had a successful fit with the interference of 0.0095’’.
          </p>
          <br />
          <p>
            This project was done as part of 2.008 Manufacturing class at MIT.
          </p>
          <br />
          <br />
          <em style={{ textAlign: "left" }}>
            <p>
              Tools: CAD, CAM, CNC, Thermoform, Injection Molding, Tolerances,
              Press Fit, Manufacturing.
            </p>
          </em>
          <br />
          <ImArrowDown style={{ fontSize: "xxx-large" }} />
          <br />
          <br />

          <br />
        </Container>
      </Container>
      <div style={{ backgroundColor: "#fdfcfb" }}>
        <Container style={{ textAlign: "center", justifyContent: "center" }}>
          <b>
            <p style={{ fontSize: "xxx-large", paddingTop: 80 }}>Results</p>
          </b>
          <Image src={yoyo} width="70%" style={{ marginTop: 50 }} />
          <Container>
            <Container>
              <p
                style={{
                  fontSize: "large",
                  padding: 20,
                }}
              >
                Final Yo-yo -- manufacture and assembled. In total, over 130
                yo-yos were manufactured with an average of 4.5 min/yo-yo. 4
                parts were injection molded, 1 part was thermoformed, and then
                all parts were assembled together.
              </p>
            </Container>
          </Container>
        </Container>
        <br />
        <br />
        <Container>
          <p>
            I am proud of my design of this part and the molds that I CNCed for
            IM. In 6 hours in the lab, five people manufactured and assembled 80
            yo-yos, averaging 4.5 min/yo-yo, the second-best result in class.
          </p>

          <br />
          <p> If I were to approach this design now, I would focus on:</p>
          <br />
          <ul style={{ fontSize: "x-large" }}>
            <li>adding more mass to the yo-yo to improve its spin duration</li>
            <br />

            <li>
              changing the position of the interface between parts to ensure
              that the string never gets caught on the yo-yo
            </li>
            <br />

            <li>
              experiment with design shape to make it more appealing to the user
              (ex. by making the yo-yo rounder, adding butt cheeks at the back
            </li>
          </ul>
          <br />

          <hr />
        </Container>
        <Container style={{ textAlign: "center", justifyContent: "center" }}>
          <b>
            <p style={{ fontSize: "xxx-large", paddingTop: 40 }}>Design</p>
          </b>
          <Row style={{ marginTop: 80 }}>
            <Col style={{ backgroundColor: "#e1dfd9", margin: 50 }}>
              <Image src={yoyo2} style={{ width: "100%", padding: 50 }} />
              <b>
                <p>First Iteration of the </p>
                <p>Yo-Yo Core</p>
              </b>
              <div style={{ fontSize: "large", padding: 20 }}>
                <br />
                <p>
                  In my initial design, I aimed to make it as simple of the
                  design as possible.
                </p>
                <br />
                <p>
                  However, after discussing it with the team and experienced
                  machinists, I noticed many defects that made the part
                  unmanufacturable in CAM/IM, including:
                </p>
                <br />
                <ul>
                  <li>
                    lack of draft angles (the part won’t come out of the mold) -
                    very thin lip (impossible to IM)
                  </li>
                  <br />
                  <li>
                    - small interference fit area (won’t produce a high-quality
                    assembly)
                  </li>
                </ul>
              </div>
            </Col>
            <Col style={{ backgroundColor: "#e1dfd9", margin: 50 }}>
              <Image
                src={yoyo3}
                style={{
                  width: "100%",
                  paddingTop: 60,
                  paddingRight: 40,
                  paddingLeft: 40,
                  paddingBottom: 50,
                }}
              />
              <b>
                <p>Final Design of the</p>
                <p> Yo-Yo Core</p>
              </b>
              <div style={{ fontSize: "large", padding: 20 }}>
                <br />
                <p>
                  Having gone through several iterations, I settled on the yo-yo
                  that could be easily and consistently manufactured.
                </p>
                <br />
                <p>Some of the improvements include:</p>
                <br />
                <ul>
                  <li>
                    added draft angles (2 deg angle chosen to match available 2
                    deg CNC mill to speed up mold mfg)
                  </li>
                  <br />
                  <li>
                    smoothed edges (90 deg angles are hard to fill in IM and
                    manufacture on CNC)
                  </li>
                  <br />
                  <li>
                    symmetrical pieces (improved weight distribution and
                    improved quality of IM parts)
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
    </div>
  );
};
export default YoYo;
