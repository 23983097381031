import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import oshkosh from "../assets/oshkosh.png";
import { ImArrowDown } from "react-icons/im";
import oshkoshVid from "../assets/oshkoshVid.gif";
const Oshkosh = () => {
  return (
    <div style={{ marginTop: 50, backgroundColor: "transparent" }}>
      <Container>
        <b
          style={{
            textAlign: "center",
            fontSize: "xxx-large",
            marginTop: 50,
          }}
        >
          <p>MIT - OSHKOSH</p>
          <p>NEXT GEN HYDRAULICS WITHOUT FLUIDS</p>
        </b>
        <br />
        <Container style={{ textAlign: "center" }}>
          <p>
            Worked on a senior 4-member team capstone project in close
            collaboration with Oshkosh on designing next-generation high-force
            long-stroke electromechanical linear actuators that can fully
            replace hydraulics in Oshkosh effort to electrify their entire
            fleet.
          </p>
          <br />
          <p>
            This project was done as part of 2.76 Global Design class under
            Prof. Amos Winter at MIT in close collaboration with Oshkosh.
          </p>
          <br />
          <br />
          <em style={{ textAlign: "left" }}>
            <p>
              Tools: CAD, CAM, CNC, DFM, Stress, Communication, Customer Value
              Chain, Market Research.
            </p>
          </em>

          <ImArrowDown style={{ fontSize: "xxx-large" }} />

          <br />
          <br />
          <br />
          <br />
        </Container>
      </Container>
      <div style={{ backgroundColor: "#fdfcfb" }}>
        <Container>
          <b>
            <p
              style={{
                fontSize: "xxx-large",
                paddingTop: 80,
                textAlign: "center",
              }}
            >
              Process
            </p>
          </b>
          <br />
          <Row style={{}}>
            <Col style={{ fontSize: "x-large", padding: 40 }}>
              <b>
                <p>Key challenges:</p>
              </b>
              <br />
              <ul>
                <li>
                  large length collapsed and extended lengths (7ft collapsed, 3x extension)
                </li>
                <li>high force applicaiton</li>
                <li>high efficiency and reliability requirements</li>
              </ul>
              <br />
              <p>
                After initial market research and with the help of the team and
                our advisors, I have come up with several concepts that could
                address the project's challenges.
              </p>
            </Col>
            <Col style={{ alignSelf: "center" }}>
              <Image src={oshkosh} style={{ width: "100%", padding: 20 }} />
            </Col>
          </Row>
          <br />
          <br />
          <Row style={{}}>
            <Col style={{ alignSelf: "center", textAlign: "center" }}>
              <Image src={oshkoshVid} width="70%" />
              {/* <Image src={oshkosh} style={{ width: "100%", padding: 20 }} /> */}
              <p style={{ fontSize: "medium", textAlign: "center" }}>
                Lifting a 2kg load up and down in real-time.
              </p>
            </Col>
            <Col
              style={{ fontSize: "x-large", padding: 40, alignSelf: "center" }}
            >
              <p>
                After concept downselection, a 1/14 scale prototype was designed
                and manufactured.
              </p>
              <br />
              <ul>
                <li>
                  The first prototype was 3D printed to finalize the mechanism
                  design and assembly.
                </li>
                <li>
                  The second prototype was custom-manufactured out of steel to
                  resemble the full-scale material selection. It was then tested
                  and confirmed that the scaled-down prototype can hit desired
                  speed and load, making the selected design plausible.
                </li>
              </ul>
              <br />
              <p>
                At the same time, the prototype highlighted some of the
                challenges that need to be addressed to guarantee the success of
                the full-scale version.
              </p>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
      </div>
    </div>
  );
};
export default Oshkosh;
