import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import nerf from "../assets/nerf.jpg";
import nerf2 from "../assets/nerf2.png";
import nerf3 from "../assets/nerf3.png";
import nerf4 from "../assets/nerf4.png";
import nerf5 from "../assets/nerf5.png";
import { ImArrowDown } from "react-icons/im";

const Nerf = () => {
  return (
    <div style={{ marginTop: 50, backgroundColor: "transparent" }}>
      <Container>
        <b
          style={{
            textAlign: "center",
            fontSize: "xxx-large",
            marginTop: 50,
          }}
        >
          <p>TDC HACKATHON</p>
          <p>AUTOMATED NERF GUN TURRET</p>
        </b>
        <br />
        <Container style={{ textAlign: "center" }}>
          <p>
            I designed a 2 Degree-of-Freedom motor-operated mount for the nerf
            gun to fit specific design requirements, creating a turret able to
            follow and hit a human walking around the room.
          </p>
          <br />
          <p>
            With this project, my teammate and I won a PS5 for 1st place at a
            TDC hackathon.
          </p>
          <br />
          <br />
          <em style={{ textAlign: "left" }}>
            <p>Tools: CAD, 3D printing, electronics and motors.</p>
          </em>
          <br />

          <ImArrowDown style={{ fontSize: "xxx-large" }} />

          <br />
          <br />
        </Container>
      </Container>
      <div style={{ backgroundColor: "#fdfcfb" }}>
        <Container>
          <b>
            <p
              style={{
                fontSize: "xxx-large",
                paddingTop: 80,
                textAlign: "center",
              }}
            >
              Goals
            </p>
          </b>
          <br />
          <Row style={{}}>
            <Col style={{ alignSelf: "center", padding: 50 }}>
              <Image src={nerf2} style={{ width: "100%" }} />
              <p style={{ fontSize: "large", textAlign: "center" }}>
                3D rendering of the nerf gun turret base.
              </p>
            </Col>
            <Col
              style={{ alignSelf: "center", padding: 50, fontSize: "x-large" }}
            >
              <ul>
                <li>
                  Modify a Nerf Gun to be controlled remotely and automatically
                  find and lock on the target, turn in two dimensions (sides and
                  up-down) and “shoot” a target. The project should be low
                  budget.
                </li>
                <br />
                <br />
                <li>
                  Create a fun project to prank our roommates when they come
                  back from visiting their families and to pass the time while I
                  couldn’t go outside due to my leg injury.
                </li>
              </ul>
            </Col>
          </Row>
          <b>
            <p
              style={{
                fontSize: "xxx-large",
                paddingTop: 80,
                textAlign: "center",
              }}
            >
              Process and Challenges
            </p>
          </b>
          <br />

          <Row style={{}}>
            <Col
              style={{ alignSelf: "center", padding: 50, fontSize: "x-large" }}
            >
              <p>
                I designed the support and a rotational mechanism for the Nerf
                Gun. I initially designed the mounting mechanism to be a
                one-piece, but it couldn’t fit into the base of the available 3D
                printer. So, I decided to split it into two and print parts and
                joints such that they would be reinforced by the inner layered
                structure of a 3D-printed piece. Such modification outperformed
                the original plan.
              </p>
              <p>
                I also had to work around the wiring and the rotating mechanism
                of the spool. To allow the turret to spin more than 360 deg
                without tangling the wires, I incorporated an electrical slip
                ring.
              </p>
            </Col>
            <Col style={{ alignSelf: "center", padding: 50 }}>
              <Image src={nerf3} style={{ width: "100%" }} />
              <p style={{ fontSize: "large", textAlign: "center" }}>
                The displacement simulation was used to minimize deflection
                while minimizing the material used in the base and ensuring that
                PLA (available material at a time) would be sufficient for the
                use case..
              </p>
            </Col>
          </Row>
          <b>
            <p
              style={{
                fontSize: "xxx-large",
                paddingTop: 80,
                textAlign: "center",
              }}
            >
              Results and Future Considerations
            </p>
          </b>
          <br />
          <p style={{ padding: 50, fontSize: "x-large" }}>
            I highly enjoyed working on this project with my friend because I
            learned a lot about computer vision and motor controls. Working on a
            team with an electrical and computer engineer was great because I
            learned so much about the wiring and software constraints I need to
            keep in mind when designing electromechanical systems.
          </p>
          <Row style={{ display: "flex", flexWrap: "wrap" }}>
            <Col style={{ padding: 50 }}>
              <Image src={nerf} width="100%" />
              <Image src={nerf5} width="100%" />
            </Col>
            <Col style={{ padding: 50 }}>
              <Image src={nerf4} width="100%" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default Nerf;
