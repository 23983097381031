import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import About from "./pages/About";
import Oshkosh from "./pages/Oshkosh";
import Nerf from "./pages/Nerf";
import YoYo from "./pages/YoYo";
import E33 from "./pages/E33";
import Lathe from "./pages/Lathe";
import Research from "./pages/Research";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <NavBar />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/oshkosh" element={<Oshkosh />} />
            <Route path="/nerf" element={<Nerf />} />
            <Route path="/yoyo" element={<YoYo />} />
            <Route path="/research" element={<Research />} />
            <Route path="/e33" element={<E33 />} />
            <Route path="/lathe" element={<Lathe />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </header>
    </div>
  );
}

export default App;
