import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import tens_photo from "../assets/tens_photo.png";
import tensegrity from "../assets/tensegrity.jpeg";
import tensmoving from "../assets/tens_moving_2_square.gif";
import prototype1 from "../assets/Tensegritydesign.jpg";
import prototype2 from "../assets/Tensegritydesign2.png";
import inspiration from "../assets/pelvic-floor.gif";
import case1 from "../assets/TensegrityDrop.jpg";
import case2 from "../assets/tensegritycoordinated.png";
import case3 from "../assets/metreadmill.JPG"
import { ImArrowDown } from "react-icons/im";
// import researchVid from "../assets/nerf.gif";
const Research = () => {
  return (
    <div style={{ marginTop: 50, backgroundColor: "transparent" }}>
      <Container>
        <b
          style={{
            textAlign: "center",
            fontSize: "xxx-large",
            marginTop: 50,
          }}
        >
          <p>MIT.nano - Immersion Lab</p>
          <p>Biomechanics Research</p>
        </b>
        <b
          style={{
            textAlign: "center",
            fontSize: "x-large",
            marginTop: 50,
          }}
        >
        
        <p>Exploring Efficiency of Human Movement</p>
        </b>
        <br />
        <Container style={{ textAlign: "center" }}>
        <Row style={{textAlign: "center", fontSize: "x-large", marginTop: 50}}>
          <Col>
          <p>
          Personal Projects: 
          </p>
          <ul>
            <li>developed and led $1M biomechanics data collection projects</li>
            <li>designed novel actuation mechanism for tensegrity structures inspired by human movement</li>
            <li>developed 1ms precision clock system to synchronize clock for real-time multimodal movement and muscle observation</li>
            <li>custom scripts (Python, bash, blender) for processing automation and data visualization for 100+ gb of data, reducing manual process time by 90%</li>
        </ul>   
          <br />
          <br />
          <em style={{ textAlign: "left" }}>
            <p>
            Skills: MATLAB, blender, pandas, numpy, DAQ, ffmpeg, arduino RealityCapture, Motive, Delsys Trigno, scipy, git.
            </p>
          </em>
          </Col>
          <Col>
          <Image src={tensmoving} style={{padding: 10 }}  resizeMode='contain'/>
          <p>| Tensegrity Robot, Early Prototype  | Summer’22</p>
          </Col>
          </Row>
          <br />
          <ImArrowDown style={{ fontSize: "xxx-large" }} />

          <br />
          <br />
          <br />
        </Container>
      </Container>
      <div style={{backgroundColor:"#fdfcfb"}}>
          <Container style={{ textAlign: "center", fontSize: "large",}}>
          <br />
          <br />
          <p style={{fontSize: "xx-large"}}>Design Requirements & Ideation</p>
          <br />
          <ul>
            <li>1 DOF actuation of a pre-defined tensegrity ball</li>
            <li>Organic moement mimicking core contraction-expansion cycle (such as inhale-exhale cycle)</li>
        </ul> 
        <br />
            <Row>
              <Col>
              <Image src={prototype1} style={{width: "100%", padding: 5 }}/>
              <p>Brainstorming ideas</p>
              <br />
              <br />
              </Col>
              <Col>
              <Image src={prototype2} style={{width:"60%", padding: 5 }}/>
              <p>Mechanism Design</p>
              </Col>
              <Col>
              <Image src={inspiration} style={{width:"60%", padding:5 }}/>
              <p>Inspiration</p>
              </Col>
            </Row>
          </Container>
          <Container style={{ textAlign: "center", fontSize: "large",}}>

          </Container>
      </div>
      <div style={{ backgroundColor: "transparent", textAlign: "center"}}>
        <Container>
          <br />
          <p>Case study: Comparing Tensegrity Structures and Human Body</p>
          <br />
          <Row>
            <Col>
            <Image src={case1} style={{width:"100%", padding:5 }}/>
            <br />
            <p>Tensegrity Structures</p>
            <br />
            <p style={{fontSize:"large"}}>Mathematical models of tensegrity structures are computationally heavy and generally lack accuracy. However, we can make predictions about system response in tensegrity structures when we compare them to the human body. Similarly, analyzing tensegrity structures helps us better understand the laws governing human movement.</p>
            <br />
            </Col>
            <Col>
            <Image src={case2} style={{width:"100%", padding:5 }}/>
            <br />
            <p>Muscle length oscillations during running</p>
            <p style={{fontSize:"large"}}>The human body is a tensegrity structure, in which bones are rigid elements, and muscles and soft tissue are elastic elements. We often think of our body as a skeleton with some muscles overlayed on top. In reality, our bones are suspended in the air and move solely thanks to the stretches and contractions of our soft tissues. </p>
            <br />
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#fdfcfb", textAlign: "center"}}>
        <Container>
          <br />
          <p> Highligted Project: Exploring the effects of music on synchronization of muscles and muscle groups</p>
          <br />
          <Row>
            <Col>
            <Image src={case3} style={{width:"100%", padding:5 }}/>
            <p style={{fontSize:"large"}}>Example of the setup and the subject during data collection</p>
            <br />
            </Col>
            <Col>
            <br />
            <p>Muscle length oscillations during running</p>
            <p style={{fontSize:"large"}}>After collecting promising preliminary data and discussing our findings and theories with experts across dance, physical therapy, sports, and robotics, I decided to start a human subject research. </p>
            <ul style={{fontSize:"large", textAlign: "left"}}>
              <li>wrote the protocol for 3 studies (approved by Internal Review Board)</li>
              <li>recruited 40+ subjects in Boston and led communication with the subjects before, during, and after the study</li>
              <li>trained and guided 5 students during data collection sessions</li>
              <li>co-authored a streamline to process 10+ gb of raw data</li>
              <li>working towards several publications</li>
            </ul>

        <p style={{fontSize:"large"}}>Special thanks to <a href="https://praneethnamburi.com/">Dr. Praneeth Namburi</a> for his mentorship.</p>
            <br />
            </Col>
          </Row>
        </Container>
      </div>

      <div style={{ fontSize:"x-large", backgroundColor: "transparent", textAlign: "center"}}>
        <Container>
          <br />
          <p style={{ fontSize:"xxx-large"}}> Immersion Lab — Hub for biomechanics research and education</p>
          <br />
            <br />
            <p><b>What is Immersion Lab?</b></p>
            <p style={{ fontSize:"large"}}><a href="https://nanousers.mit.edu/immersion-lab">Immersion Lab</a> is an environment that connects digital and physical objects. With the technology to capture the positions of small markers in space with a precision of 1-2 mm, I was thrilled about the possibilities that Immersion Lab equipment opens in biomechanics research. </p>
            <br />
            <p><b>What tools did I use in Immersion Lab?</b></p>
            <ul style={{fontSize:"large", textAlign: "left"}}>
              <li><a href="https://www.optitrack.com/">Optitrack</a> Motion Capture: By placing IR markers on the skin along the muscles and <a href="https://www.anatomytrains.com/about-us/">muscle lines</a>, we’re able to capture periodic muscle length changes and analyze the effects of muscle synchronization on the stability and efficiency of human movement. </li>
              <li><a href="https://delsys.com/trigno/">Delsys Trigno Sensors</a> (EMG, FSR, Accelerometer, etc)</li>
              <li>trained and guided 5 students during data collection sessions</li>
              <li>co-authored a streamline to process 10+ gb of raw data</li>
              <li>working towards several publications</li>
            </ul>
            <br />
            <p><b>Why am I interested in biomechanics?</b></p>
            <p style={{ fontSize:"large"}}>Movement has been an integral part of human life — it helps us travel, communicate, manage our stress and share our emotions. Many disciplines from dance to yoga and martial arts developed their own ways of optimizing movement and improving connection to oneself and the world. </p>
            <p style={{ fontSize:"large"}}>The goal of our projects is to quantify and generalize principles, underlying the efficiency of human movement. A general, quantifiable system will be revolutionary — it will allow us to train faster, to communicate our experience more efficiently, as well as improve recovery and well-being of other humans. By better understanding the underlying principles of human movement, I hope to be able to build more efficient robots that rely on tensegrity structures and energy storage and retrieval cycles just like our body does.</p>
            <br />
            <p>Want to learn more about recent projects in Immersion Lab? Check out Immersed series recordings on our <a href="https://www.youtube.com/@MITnano/playlists">Youtube channel.</a> </p>
            <br />
        </Container>
      </div>
      <div style={{ backgroundColor:"#fdfcfb" }}>
        <Container>
          <b>
            <p
              style={{
                fontSize: "xxx-large",
                paddingTop: 80,
                textAlign: "center",
              }}
            >
              Tensegrity Robot
            </p>
          </b>
          <br />
          <Row style={{}}>
          <Col style={{ alignSelf: "center" }}>
              <Image src={tensegrity} style={{ width: "100%", padding: 20 }} />
            </Col>
            <Col style={{ fontSize: "x-large", padding: 40 }}>
              <b>
                <p>What is tensegrity?</p>
              </b>
              <br />
                <p>
                Tensegrity (tensional integrity)  is a structural principle based on a system of isolated components under compression inside a network of continuous tension.The human body can be described as a tensegrity structure, where bones are suspended in the air by muscles and other soft tissue. 
                </p>
              <br />
              </Col>
          </Row>
          <br />
          <Row style={{}}>
          <Col style={{ alignSelf: "center" }}>
              <Image src={tens_photo} style={{ width: "100%", padding: 20 }} />
            </Col>
            <Col style={{ fontSize: "x-large", padding: 40 }}>
              <b>
                <p>Why tensegrity?</p>
              </b>
              <br />
                <p>
                The human body can be described as a tensegrity structure, where bones are suspended in the air by muscles and other soft tissue. 
                The most common model for the human body is a rigid <b>spring-damper</b> system. It is often <b>oversimplified</b> as it doesn’t account for <b>over 60% of the energy</b> stored and dissipated in soft tissue. Still, these models are used to develop novel products, including <b>prosthetics, robotics</b>, etc. I use modern technology to develop <b>robust tensegrity models</b> for human movement and <b>novel, efficient tensegrity robots</b> that mimic muscles. 
                </p>
              <br />

              <b>
                <p>My Design</p>
              </b>
              <br />
                <ul>
                    <li>use MATLAB tensegrity models to measure stress and strain</li>
                    <li>use 3D printing, LEGO electronics, and Arduino to build and control tensegrity robots (see photo)</li>
                    <li>develop MATLAB code to generate custom tensegrity structures based on the provided restrictions (in progress)</li>
                </ul>  
              <br />
              </Col>
          </Row>
        </Container>
        <br />
        <br />
      </div>
    </div>
  );
};
export default Research;
