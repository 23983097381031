import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const About = () => {
  return (
    <Container style={{ marginTop: 50, backgroundColor: "transparent" }}>
      <Row>
        <Col sm={4}>
          <em>
            <p style={{ fontSize: "x-large" }}>About me</p>
          </em>
        </Col>
        <Col sm={8}>
          <hr />
        </Col>
      </Row>

      <br />
      <h2 style={{ fontSize: "xxx-large" }}>
        <b>From Kyiv, Ukraine. At home everywhere.</b>
      </h2>
      <br />

      <p>
        I enjoy studying and utilizing the <em>connection</em> and{" "}
        <em>communication</em> between various parts of large, complex systems.
        Whether I’m developing a new product or analyzing frequencies of muscle
        oscillations in runners, swimming,  rock climbing or playing DnD, my goal is{" "}
        <b>to get in touch with the process</b>.{" "}
      </p>
      <br />

      <p>
        I enjoy traveling and learning from other people, as global perspectives
        and global approach is key to solving some of the largest problems we’re
        now facing as species.{" "}
      </p>
      <br />
      <hr />
      <br />
      <br />
    </Container>
  );
};

export default About;
