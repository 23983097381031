import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import oshkosh from "../assets/oshkosh.png";
import { ImArrowDown } from "react-icons/im";
import lathecad from "../assets/lathecad.png";
import lathecadslice from "../assets/CADslice.png";
import spindle1 from "../assets/spindle.png";
import xslide1 from "../assets/xslide_flexure.png";
import xslide2 from "../assets/xlslide_fab.png";
import HTM from "../assets/HTM.png";
import FABlearn from "../assets/fablearnings.png";
import Lathevid from "../assets/lathevid.gif";
const Lathe = () => {
  return (
    <div style={{ marginTop: 50, backgroundColor: "transparent" }}>
      <Container>
        <b
          style={{
            textAlign: "center",
            fontSize: "xxx-large",
            marginTop: 50,
          }}
        >
          <p>Tabletop Lathe</p>
          <p>Design and Fabrication</p>
        </b>
        <br />
        <Container style={{ textAlign: "center" }}>
          <p>
          Led a cross-functional team of 6 in the rapid design and fabrication of
           a tabletop lathe, successfully navigating time constraints make 
           a lathe with <b>sub 50 micron precision</b> in 3 months on schedule.
          </p>
          <br />
          <p>
            This project was done as part of 2.72 Elements of Mechanical Design class under
            Prof. Marty Culpepper at MIT.
          </p>
          <br />
          <br />
          <em style={{ textAlign: "left" }}>
            <p>
              Tools: Solidworks (CAD, FEA, Collaboration), Waterjet, DFM, Tolerance Stacking, Stress, Communication.
            </p>
          </em>

          <ImArrowDown style={{ fontSize: "xxx-large" }} />

          <br />
          <br />
          <br />
          <br />
        </Container>
      </Container>
      <div style={{ backgroundColor: "#fdfcfb" }}>
        <Container>
          <b>
            <p
              style={{
                fontSize: "xxx-large",
                paddingTop: 80,
                textAlign: "center",
              }}
            >
              Process
            </p>
          </b>
          <br />
          <Row style={{}}>
            <Col style={{ fontSize: "x-large", padding: 40 }}>
              <b>
                <p>Key challenges:</p>
              </b>
              <br />
              <ul>
                <li>
                  define key Design Requirements (DRs) for each sub-assembly (spindle, cross-slide, lead screw)
                </li>
                <li>design and fabricate each sub-assembly within the defined DRs </li>
                <li>model and measure each sub-assembly to demonstrate performance withing the defined DRs</li>
              </ul>
              <center><Image src={lathecadslice} style={{ width: "100%", padding: 5 }} /></center>
            </Col>
            <Col style={{ alignSelf: "center" }}>
              <center><Image src={lathecad} style={{ width: "100%", padding: 20 }} /></center>
              <p style={{ fontSize: "medium", textAlign: "center" }}>
                CAD of the lathe assembly in Solidworks.
              </p>
            </Col>
          </Row>
          <br />
          <br />
          <Row style={{}}>
            <Col style={{ alignSelf: "center", textAlign: "center" }}>
              <Image src={spindle1} width="100%" />
              {/* <Image src={oshkosh} style={{ width: "100%", padding: 20 }} /> */}
              <p style={{ fontSize: "medium", textAlign: "center" }}>
                Spindle CAD and Fabrication
              </p>
            </Col>
            <Col
              style={{ fontSize: "x-large", padding: 40, alignSelf: "center" }}
            >
              <p>
                Spindle Assembly
              </p>
              <ul style={{ fontSize: "large", padding: 40, alignSelf: "center" }}>
                <li>Total allocated error for the spindle was 44.1 microns 
                  out of 50 microns DR, as the spindle was the most complex contribution 
                  to the radial error. Total Spindle deflection (error) was measured to be  
                </li>
                <li>
                  Spindle shaft was constrained by a pair of tapered roller bearings. 
                  The bearings were preloaded with wave springs, although  
                  Belleville washers were also considered.
                </li>
              </ul>
            </Col>
          </Row>
          <br />
          <br />
          <Row style={{}}>
            <Col style={{ alignSelf: "center", textAlign: "center" }}>
              <Image src={xslide1} width="100%" />
              <p style={{ fontSize: "medium", textAlign: "center" }}>
                Cross-Slide CAD, FEA
              </p>
              <Image src={xslide2} width="100%" />
              <p style={{ fontSize: "medium", textAlign: "center" }}>
                Cross-Slide Fabrication
              </p>
            </Col>
            <Col
              style={{ fontSize: "x-large", padding: 40, alignSelf: "center" }}
            >
              <p>
                Cross-Slide Assembly
              </p>
              <ul style={{ fontSize: "x-large", padding: 40, alignSelf: "center" }}>
                <li>Flexure was used to minimize errors and vibrations during cutting.
                </li>
                <li>
                  Flexure design was optimized in Solidworks FEA and fabricated on a water jet.
                </li>
              </ul>
            </Col>
          </Row>
          <br />
          <br />
          <Row style={{}}>
            <Col style={{ alignSelf: "center", textAlign: "center" }}>
              <Image src={HTM} width="100%" />
              
              <p style={{ fontSize: "medium", textAlign: "center" }}>
                Cross-Slide CAD, FEA
              </p>
            </Col>
            <Col
              style={{ fontSize: "x-large", padding: 40, alignSelf: "center" }}
            >
              <p>
                Assembly and Error Stacking
              </p>
              <ul style={{ fontSize: "x-large", padding: 40, alignSelf: "center" }}>
                <li>Homogenous Transformation Matrices (HTM) was used to model error stacking across the assembly.
                </li>
                <li>
                  Total error was continiously evaluated using HTM and measured using
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col style={{ alignSelf: "center", textAlign: "center" }}>
            <br />
            <br />
            <p>Lathe Cutting in action!</p>
            <Image src={Lathevid} width="70%" />
            <br />
            <br />
            <p>Some of the fabrication learnings (and bloopers):</p>
            <Image src={FABlearn} width="70%" />
            </Col>
            </Row>
        </Container>
        <br />
        <br />
      </div>
    </div>
  );
};
export default Lathe;
